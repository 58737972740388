<script>
export default {
  name: 'HelperDownload',
  props: {
    title: {
      default: 'Download do Exemplo',
      type: String,
    },
    href: {
      type: String,
      required: true,
    },
  },
}
</script>

<template>
  <small class="form-text text-muted mt-0 pt-0">
    <a :href="href" target="_blank">
      {{ title }}
    </a>
  </small>
</template>

<style scoped>

</style>
