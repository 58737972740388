<template>
  <div>
    <b-badge v-if="value" variant="success" style="width: 100%">
      <feather-icon icon="CheckCircleIcon" class="mr-1" />
      Sim
    </b-badge>
    <b-badge v-if="!value" variant="danger" style="width: 100%">
      <feather-icon icon="CircleIcon" class="mr-1" />
      Não
    </b-badge>
  </div>
</template>
<script>

export default {
  name: 'SBooleanBadge',
  props: {
    value: { type: Boolean },
  },
}
</script>
