<template>
  <b-modal
    id="form-sidebar"
    ref="sideBar"
    :visible="isActive"
    backdrop
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    bg-variant="white"
    size="lg"
    shadow
    title="Cadastro de Locais"
    sidebar-class="sidebar-lg"
    header-class="content-sidebar-header"
    footer-class="content-sidebar-footer"
    :hide-footer="true"
    @change="(val) => $emit('update:is-active', val)"
  >

    <template #default="{ hide }">
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >

        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <s-autosuggest
                v-model="model.name"
                url="locations?search=search:"
                rules="required"
                label="Nome"
                placeholder=""
                name="name"
                text-field="name"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12">
              <i2-form-input-money
                v-model="model.average_cost"
                label="Custo Médio"
                placeholder=""
                name="average_cost"
              />

            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="6"
              md="6"
            >
              <i2-form-input
                v-model="model.responsible.name"
                label="Responsável"
                placeholder=""
                name="responsible"
              />
            </b-col>
            <b-col
              sm="6"
              md="6"
            >
              <i2-form-input
                v-model="model.responsible.email"
                label="E-mail do responsável"
                placeholder=""
                type="email"
                rule="email"
                name="email"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col
              sm="12"
              md="12"
              class="text-right"
            >

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="button"
                class="mr-2"
                @click="onSubmit"
              >

                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                Salvar
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-danger"
                type="button"
                @click="onCancel"
              >

                <font-awesome-icon
                  prefix="far"
                  :icon="['far', 'times-circle']"
                  class="mr-50"
                />
                Cancelar
              </b-button>

            </b-col>
          </b-row>

        </b-form>

      </validation-observer>
    </template>

  </b-modal>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { alphaNum, required } from '@validations'
import { ValidationObserver } from 'vee-validate'
import SAutosuggest from '@/components/i2/forms/elements/s-autosuggest.vue'

export default {
  name: 'LocationForm',
  directives: {
    Ripple,
  },
  components: {
    SAutosuggest,
    ValidationObserver,
  },
  props: {
    uuid: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      model: {
        uuid: null,
        external_id: '',
        name: '',
        average_cost: '',
        responsible: {
          name: '',
          email: '',
          phone: '',
        },
      },
    }
  },
  watch: {
    uuid(uuid) {
      if (uuid) {
        this.getData(uuid)
      }
    },
  },
  methods: {

    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          let response = {}
          const data = { ...this.model }
          for (const field in data) {
            data[field] = data[field]
            if (data[field] != null && data[field].value) {
              data[field] = data[field].value
            }
          }

          if (this.model.uuid) {
            response = await this.$http.put(`locations/${this.model.uuid}`, data)
          } else {
            response = await this.$http.post('locations', data)
          }
          if (response.error) {
            this.$notify.error(response.error_message)
            return
          }
          this.$notify.success('Local salvo com sucesso.')
          this.$emit('onSave', true)
          this.resetModel()
        }
      })
    },
    onCancel() {
      this.$emit('onCancel', true)
      this.resetModel()
      this.$refs.refFormObserver.reset()
    },
    async getData(uuid) {
      this.model = await this.$http.get(`locations/${uuid}`)
    },
    resetModel() {
      this.model = {
        uuid: null,
        external_id: '',
        name: '',
        average_cost: '',
        responsible: {
          name: '',
          email: '',
        },

      }
      this.$refs.refFormObserver.reset()
    },
  },
}
</script>

<style scoped>

</style>
