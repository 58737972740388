<template>
  <div style="padding-bottom: 4px">
    <i2-form-input v-model="selection"
                   :label="label"
                   :name="name"
                   :placeholder="placeHolderLabel"
                   style="margin-bottom:0px"
                   @keydown.enter="enter"
                   @keydown.down="down"
                   @keydown.up="up"
                   @keydown="change"
                   @keydown.esc="close"
    />
    <ul v-if="openSuggestion" class="list" style="width:100%" :class="{'open':openSuggestion}">
      <li v-for="(suggestion, i) of matches" :id="name+'_option_'+i"
          :key="i"
          :class="{'active': isActive(i)}"
          @click="suggestionClick(i)"
      >
        <a href="#" v-html="suggestion" />
      </li>
    </ul>
  </div>
</template>

<script>

import debounce from 'lodash/debounce'

export default {
  name: 'SAutosuggest',
  props: {
    suggestions: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      type: String,
      required: true,
      default: '',
    },
    textField: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      open: false,
      current: 0,
      selection: '',
      matches: [],
    }
  },
  computed: {
    placeHolderLabel() {
      if (this.selection !== '' && this.matches.length > 0) {
        return this.matches[0]
      }
      return ''
    },
    openSuggestion() {
      return this.selection !== ''
            && this.matches.length !== 0
            && this.open === true
    },
  },
  watch: {
    async selection(val) {
      if (!this.url || val === '') {
        return
      }
      this.matches = this.fetchOptions(val)
      this.$emit('input', val)
    },
    value(data) {
      this.selection = data
    },
  },
  mounted() {
    this.inputValue = this.value
  },
  methods: {
    async fetchOptions(search) {
      const query = search.replaceAll(/\\/g, '\\\\')
      this.$http.showLoader = false
      const data = await this.resourceSearch(this.url + query, this, this.textField)
      this.$http.showLoader = true
      this.options = data
      return data
    },
    resourceSearch: debounce((search, vm, textField) => {
      // eslint-disable-next-line no-invalid-this
      vm.$http.showLoader = false
      vm.$http.get(search).then(res => {
        const itens = []
        const response = res.data || res
        for (const data of response) {
          itens.push(
            data[textField],
          )
        }
        vm.matches = itens
        vm.$http.showLoader = true
      })
    }, 350),
    enter() {
      this.selection = this.matches[this.current]
      this.open = false
    },

    up() {
      if (this.current > 0) {
        // eslint-disable-next-line no-plusplus
        this.current--
        const id = `${this.name}_option_${this.current}`
        const el = document.getElementById(id)
        el.scrollIntoView(true)
      }
    },

    down() {
      if (this.current < this.matches.length - 1) {
        // eslint-disable-next-line no-plusplus
        this.current++
        const id = `${this.name}_option_${this.current}`
        const el = document.getElementById(id)
        el.scrollIntoView(false)
      }
    },

    isActive(index) {
      return index === this.current
    },

    change() {
      if (this.open === false) {
        this.open = true
        this.current = 0
      }
    },

    close() {
      this.open = false
      this.current = 0
    },
    suggestionClick(index) {
      this.selection = this.matches[index]
      this.open = false
    },
    isInViewport(element) {
      const rect = element.getBoundingClientRect()
      return (
        rect.top >= 0
          && rect.left >= 0
          && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
          && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    },
  },
}

</script>
<style scoped>
li {
  list-style: none;
  margin :auto; /* This Works */
  padding: auto; /* This Not :( */
  display: block;
  padding: 5px;

}
ul{
  padding: 0 0px;
}

.active  {
  color: var(--light);
  background-color: var(--primary);
}
.active a {
  color: var(--light);
  background-color: var(--primary);
}
.open {
  border-radius: 2px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}
.list {
  max-height: 200px;
  position: absolute;
  overflow: scroll;
  background-color: white;
  top: calc(100%);
  border: none;
  border-radius: 6px;
  z-index: 999999 !important;
}
</style>
